
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

html {
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li, a {
    text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

link {
    text-decoration: none;
}

a:link {
    color: white;
}
