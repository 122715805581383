.unavailable {
  &__title {
    font-size: 2rem;
    font-weight: bold;
    margin: 3.5rem 0 1rem;
    text-align: center;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__list {
    padding: .2rem 1rem 1rem;

  }

  &__item {
    border: .05rem solid gray;
    border-radius: 1rem;
    padding: .2rem 1rem;
    margin: .5rem 0;
    background-color: pink;

    &--line {
      margin-top: .2rem;
      margin-bottom: .2rem;
      font-size: .9rem;
      flex-shrink: 1;
    }

    &--content {
      font-weight: bold;
      font-size: 1rem;
    }
  }

  &__exit {
    position: fixed;
    left: 1rem;
    top: 1rem;
    border-radius: 1rem;
    padding: .5rem;
    background-color: #ffffff88;
    box-shadow: 0 0 1rem .10rem grey;
    cursor: pointer;
    transition: .300s;

    &:hover {
    background-color: #c0c0c0aa;
    }
  }
}