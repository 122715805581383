@import '../../assets/vars.scss', '../../assets/colors.scss';


.pw {
  font-weight: bold;
  font-size: 1.2rem;
  flex-grow: 1;
  padding: 0.3rem;
  margin-left: 0.5rem;

}

@media screen and (max-width: $site-sm) {
  .pw {
    padding: 0;
    color: white;
    font-size: 1.5rem;
  }

}
