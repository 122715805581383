@import '../../assets/vars.scss', '../../assets/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Poppins:wght@400;500;600&display=swap');


.login {
    text-align: center;

}

.auth {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 1rem;
    color: red;
    font-size: 1.1rem;

    &__return {
        margin-top: 2rem;
        display: block;

        & span {
            cursor: pointer;
            color: #000;
            border-radius: 10px;
            text-decoration: none;
            padding: .5rem 1rem;
            transition: .3s;
            font-weight: bold;
            box-shadow: 0 0 5px .1px $color-text;

            &:hover {
                box-shadow: 0 0 10px 1px $color-title;
                background-color: $color-title;
                color: #fff
            }
        }
    }
}

.auth__loading {
    background-color: $color-background;

    &:hover {
        background-color: $color-background;

    }
}


.center {
text-align: center;
    margin: 0 auto;
    width: 400px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.5);
    padding-bottom: 1.5rem;
    justify-content: center;
    align-items: center;
}

.center h1 {
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid silver;
}

.center form {
    padding: 0 40px;
    box-sizing: border-box;
}

.center form .txt_field {
    position: relative;
    border-bottom: 2px solid #adadad;
    margin: 30px 0;
}

.txt_field input {
    width: 100%;
    padding: 0 5px;
    height: 40px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;
}

.txt_field label {
    position: absolute;
    top: 50%;
    left: 5px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    transition: .5s;
}

.txt_field span::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 0;
    height: 2px;
    background: $color-secondary;
    transition: .5s;
}

.txt_field input:focus~label,
.txt_field input:valid~label {
    top: -5px;
    color: $color-secondary;
}

.txt_field input:focus~span::before,
.txt_field input:valid~span::before {
    width: 100%;
}

.pass {
    margin: -5px 0 20px 5px;
    color: #a6a6a6;
    cursor: pointer;
}

.pass:hover {
    text-decoration: underline;
}

input[type="submit"] {
    width: 10rem;
    height: 50px;
    border: 1px solid;
    background-color: $color-secondary;
    border-radius: 25px;
    font-size: 18px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    transition: 1s;
}

input[type="submit"]:hover {
    border-color: $color-secondary;
    transition: .5s;
}

.signup_link {
    margin: 30px 0;
    text-align: center;
    font-size: 16px;
    color: #666666;
}

.signup_link a {
    color: $color-secondary;
    text-decoration: none;
}

.signup_link a:hover {
    text-decoration: underline;
}