@import '../../assets/vars.scss', '../../assets/colors.scss';
@import url(https://fonts.googleapis.com/css?family=Gentium+Plus:regular,700&display=swap);


.card {
  background-color: #fff;
  box-shadow: 0 10px 20px -3px #000000;
  overflow: hidden;
  border-radius: 10px;
  padding-bottom: 10px;
  flex-basis: 18rem;
  position: relative;
  transition: .2s;
  max-width: 33rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &__text-zone {
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    height: 7.5rem;
    position: relative;
    width: 100%;
    box-sizing: border-box
  }

  &__image {
    max-height: 12rem;
    text-align: center;
    position: relative;
    overflow: hidden;
    user-select: none;
    flex-grow: 1;

    &--pic {
      width: 100%;
    }
  }

  &__title {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: flex-start;
    padding: .5rem .7rem;
    gap: .5rem;
    color: #fff;
    background-image: linear-gradient(0deg,
            hsla(0, 0%, 35.29%, 0) 0%,
            hsla(0, 0%, 34.53%, 0.034375) 16.36%,
            hsla(0, 0%, 32.42%, 0.125) 33.34%,
            hsla(0, 0%, 29.18%, 0.253125) 50.1%,
            hsla(0, 0%, 24.96%, 0.4) 65.75%,
            hsla(0, 0%, 19.85%, 0.546875) 79.43%,
            hsla(0, 0%, 13.95%, 0.675) 90.28%,
            hsla(0, 0%, 7.32%, 0.765625) 97.43%,
            hsla(0, 0%, 0%, 0.8) 100%);

    &--index,
    &--name {
      font-weight: 700;
      font-family: "Gentium Plus";
      font-size: 1.4rem;
    }

    &--index {
      text-align: left;
      flex-grow: 1;
    }

    &--name {
      text-align: right;
    }

    &:hover {
      opacity: 0.5;
    }

    &--t2 {
      display: none;
    }

    &--top {

    }

    &--icon {
      color: white
    }
  }

  &__content {
    display: flex;
    padding: 0;
  }

  &__text {
    font-size: 1.3em;
    flex-grow: 1;
    margin-top: .5rem;
    padding: 0 .5rem;
    font-family: "Source Sans Pro";
  }

  &__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: right;
    padding-left: .5rem;
    flex-wrap: wrap;
  }
}

.ct--t2 {
  display: none;
}

@media screen and (max-width: $site-sm) {
  .card {
    display: flex;
    flex-direction: column;
    margin: 0 auto 1rem;
    padding: 1rem 0;
    justify-content: space-between;
    background-color: #00000077;
    min-width: 300px;
    user-select: none;
    height: 9rem;

    &__text {
      color: white;
      text-shadow: 0 0 5px black;

      &-zone {
        height: 100%;
      }
    }

    &__image {
      position: absolute;
      left: 0;
      bottom: 0;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #00000055;
      }

      &--pic {

      }
    }

    &__title {
      &--index {
        flex-grow: 0;
      }

      &--name {
        padding-left: 1rem;

      }
    }

    &__bottom {
      padding: 10px 1rem 5px;
    }
  }

  .ct--t1 {
    display: none;
  }

  .ct--t2 {
    padding: 8px 1rem;
    display: flex;
    color: $color-title;
    justify-content: flex-start;
    background-color: #ffffffaa;
    border-radius: 20px;
    align-self: start;
  }
}
