.modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  animation: show .3s;
  background-color: #000000aa;

  &__image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 20px;
    border: 2px solid #c0c0c0

  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
