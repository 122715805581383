$color-background: #c1c1c1;
$color-background-light: #ccc;
$color-text: #000;
$color-na: #00000033;
$color-link: #0051ff;
$color-title: #b45555;
$color-title-light: #b45555ee;
$color-secondary: #2691d9;
$color-shadow: #00000085;
$color-blue: #0000ff;
$color-yellow: #FFEA00;
$color-white: #FFF;
$color-gold: #FFbf00;
$color-red: #FF2400;
$color-menu-background: #ffffffcc;
$color-white-transparent: #ffffffbb;
$color-black-transparent: #00000099;
