@import '../../assets/vars.scss', '../../assets/colors.scss';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  &__content {
    display: inline-block;
    width: 80px;
    height: 80px;

    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid $color-title;
      border-color: $color-blue transparent $color-yellow transparent;
      animation: lds-dual-ring 1.2s linear infinite;
      opacity: 0.5;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
