@import '../../assets/vars.scss', '../../assets/colors.scss';

.favCardIcon {
  padding: .5rem;
  display: flex;
  align-items: center;
  z-index: 999;
  border-radius: 10px;


  &__content {
    font-size: 2.5rem;
    color: white;
  }

  position: absolute;
  left: 0;
  top: 25%
}

@media screen and (max-width: $site-sm) {
  .favCardIcon {
    opacity: .8;
    top: 0;
    bottom: 60%;
    right: 0;
    left: auto;
  }
}
