@import '../../assets/colors.scss', '../../assets/vars.scss';

.shopping {
  margin: 0 5px;
  background-color: $color-white;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-black-transparent;
    border-radius: .5rem;
  }

  &__item {
    width: 50%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-black-transparent;

    &:last-child {
      border-bottom: none;
    }


    &--unit {
      padding: 5px;
    }
  }

  &__orderButton {
    transition: .5s;

    cursor: pointer;
    margin: 1rem auto;
    background-color: $color-title;
    padding: 1rem 2rem;
    border-radius: 1rem;
    text-align: center;
    color: $color-white;
    display: block;
    width: 10rem;

    &--copied {
      background-color: $color-na;
    }

    &--menu {
      margin-top: 0;
      font-weight: bold;
      min-width: 5rem;
    }
  }

  &__counters {
    display: flex;
    align-items: center;
  }

  &__info {
    text-align: center;
    font-size: 0.9rem;
    white-space: pre-wrap;
    font-weight: bold;
    margin-bottom: 1rem;
  }
}

.unit {
  &__index {
    padding-right: .5rem;
    padding-left: .3rem;
  }

  &__title {
    white-space: pre-wrap;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__plusminus {
    user-select: none;
    width: 1.3rem;
    height: 1.3rem;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $color-shadow;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .2rem;
    cursor: pointer;
    font-size: 1.2rem;
  }


  &__count {
    width: 2rem;
    text-align: center;
    flex-shrink: 0;
  }

}


@media screen and (max-width: $site-lg) {
  .shopping {
    padding: .1rem 0;

    &__item {
      width: 75%;

    }

  }

  .unit {
    &__plusminus {
      width: 2rem;
      height: 2rem;
    }
  }
}

@media screen and (max-width: $site-sm) {
  .shopping {
    &__item {
      width: 100%;

    }

  }
}
