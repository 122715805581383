@import '../../assets/vars.scss', '../../assets/colors.scss';

.timetable {
  flex-grow: 1;
  justify-content: center;
  text-align: center;

  &__order,
  &__time,
  &__about,
  &__week {
    padding: 5px 0;
    font-size: 1.4rem;
  }

  &__order {
    &--to {
      font-weight: bold;
      text-decoration: underline;
      font-size: 1.3rem;
    }
  }

  &__time {
  }

  &__week {
  }

  &__about {

    &:hover {
      text-decoration: underline;
    }

    &--link {
      color: $color-title;
      font-weight: bold;

    }

    & a {
      color: $color-title;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 1.5rem;
    padding-bottom: .5rem;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

@media screen and (max-width: $site-lg) {
  .timetable {
    transition: .5s;
    position: fixed;
    left: -200vw;
    top: 0;
    background-color: #ffffffee;
    border-bottom-right-radius: 20px;
    box-shadow: 1px 1px 20px 2px $color-text;
    padding: 50px 30px;
    z-index: 99999;

    &__show {
      left: 0;
    }
  }
}
