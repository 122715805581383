@import '../../assets/vars.scss';

.content {
  width: 100%;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 25px;
    overflow-y: hidden;
    height: 100%;
    padding: 1rem 1rem 2rem;
  }
}

@media screen and (max-width: $site-lg) {

}

@media screen and (max-width: $site-sm) {
  .content {
    &__wrapper {
      display: block;
      padding: 1rem 1rem;

    }
  }
}
