@import '../../assets/vars.scss', '../../assets/colors.scss';


.newMenu {
    &__main {
        display: grid;
        grid-template-columns: repeat(3, 15rem);
        grid-auto-rows: auto;
        max-width: 80%;
        margin: 0 auto;
        grid-gap: 1rem;
        padding: 1rem;
        justify-content: center;
        height: 100%;
    }

    &__cell {
        background-color: $color-title;
        margin: 0.4rem 0.3rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        transition: 0.2s;
        box-shadow: 0 0 5px 0.1px $color-shadow;
        height: 4rem;
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 0.3rem 0;
        font-family: Gentium Plus;
        color: $color-white;

        &:hover {
            box-shadow: 0 0 15px 1px $color-shadow;
            transform: scale(1.03);
        }
    }

    &__title {
        color: $color-white;
    }
}

@media screen and (max-width: $site-lg) {
    .newMenu {
        &__main {
            grid-template-columns: repeat(2, minmax(170px, 10rem));
            padding: 1rem;
        }

        &__cell {
            color: $color-white;
            padding: 0.1rem;
            margin: 0.2rem 0.1rem;
            height: 3.3rem;
            font-size: 1.1rem;


            &:hover {
                transform: scale(1);
                box-shadow: 0 0 5px 0.1px $color-shadow;
            }
        }
    }
}
