@import '../../assets/colors.scss', '../../assets/vars.scss';

.detailsText {
  &__container {
    max-width: 50rem;
    margin: 0 auto;
    text-align: center;
  }

  &__text {
    padding: 2rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: "Gentium Plus";
    font-weight: bold;
  }
}

@media screen and (max-width: $site-lg) {
  .detailsText {
    &__text {
      font-size: 1.4rem;
      line-height: 1.9rem;
      padding: 0.5rem .5rem;

    }
  }
}